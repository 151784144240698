import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function App() {
  return (
    <div className="App py-5 my-5">
      <div className="mt-5 synchronicity">
        <svg viewBox="0 0 74 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M34.2858 11.4285L34.2858 28.5714C34.2858 30.1494 35.5649 31.4285 37.1429 31.4285C38.7208 31.4285 40 30.1494 40 28.5714L40 11.4285C40 9.85056 38.7208 8.57141 37.1429 8.57141C35.5649 8.57141 34.2858 9.85056 34.2858 11.4285Z" fill="black"/>
          <path d="M28.5715 37.1429L28.5715 2.8571C28.5715 1.27915 27.2923 -5.59136e-08 25.7144 -1.24888e-07C24.1364 -1.93862e-07 22.8573 1.27915 22.8573 2.8571L22.8573 37.1429C22.8573 38.7208 24.1364 40 25.7144 40C27.2923 40 28.5715 38.7208 28.5715 37.1429Z" fill="black"/>
          <path d="M17.1429 37.1429L17.1429 2.8571C17.1429 1.27915 15.8637 -5.59135e-08 14.2858 -1.24888e-07C12.7078 -1.93862e-07 11.4287 1.27915 11.4287 2.8571L11.4287 37.1429C11.4287 38.7208 12.7078 40 14.2858 40C15.8636 40 17.1429 38.7208 17.1429 37.1429Z" fill="black"/>
          <path d="M5.71423 28.5714L5.71423 11.4285C5.71423 9.85056 4.43508 8.57141 2.85713 8.57141C1.27918 8.57141 2.23554e-05 9.85056 2.22865e-05 11.4285L2.15371e-05 28.5714C2.14681e-05 30.1494 1.27918 31.4285 2.85713 31.4285C4.43508 31.4285 5.71423 30.1494 5.71423 28.5714Z" fill="black"/>
          <path d="M68.2858 11.4285L68.2858 28.5714C68.2858 30.1494 69.5649 31.4285 71.1429 31.4285C72.7208 31.4285 74 30.1494 74 28.5714L74 11.4285C74 9.85056 72.7208 8.57141 71.1429 8.57141C69.5649 8.57141 68.2858 9.85056 68.2858 11.4285Z" fill="black"/>
          <path d="M62.5715 37.1429L62.5715 2.8571C62.5715 1.27915 61.2923 -5.59136e-08 59.7144 -1.24888e-07C58.1364 -1.93862e-07 56.8573 1.27915 56.8573 2.8571L56.8573 37.1429C56.8573 38.7208 58.1364 40 59.7144 40C61.2923 40 62.5715 38.7208 62.5715 37.1429Z" fill="black"/>
          <path d="M51.1429 37.1429L51.1429 2.8571C51.1429 1.27915 49.8637 -5.59135e-08 48.2858 -1.24888e-07C46.7078 -1.93862e-07 45.4287 1.27915 45.4287 2.8571L45.4287 37.1429C45.4287 38.7208 46.7078 40 48.2858 40C49.8636 40 51.1429 38.7208 51.1429 37.1429Z" fill="black"/>
          <path d="M39.7142 28.5714L39.7142 11.4285C39.7142 9.85056 38.4351 8.57141 36.8571 8.57141C35.2792 8.57141 34 9.85056 34 11.4285L34 28.5714C34 30.1494 35.2792 31.4285 36.8571 31.4285C38.4351 31.4285 39.7142 30.1494 39.7142 28.5714Z" fill="black"/>
        </svg>
    </div>
    <h1 className="mt-5 pt-5">Coming soon! </h1>
    <div><img src={logo} className="logo" alt="logo" /></div>
</div>
  );
}

export default App;
